<template>
  <div>
    <div class="row row-sm">
      <div class="col-3">
        <label for=""> Fournisseur <span class="text-danger">*</span> </label>
        <select
          v-model="filter.supplier_reference"
          class="form-select"
          required
        >
          <option selected value="--">--</option>
          <option
            v-for="(supplier, index) in suppliers"
            :key="index++"
            :value="supplier.reference"
          >
            {{ supplier.society }}
          </option>
        </select>
      </div>

      <div class="col">
        <label for="">Du <span class="text-danger">*</span> </label>
        <input v-model="filter.startDate" type="date" class="form-control" />
      </div>
      <div class="col">
        <label for=""> Au <span class="text-danger">*</span> </label>
        <input v-model="filter.endDate" type="date" class="form-control" />
      </div>

      <div class="col-2">
        <label for=""> État de paiement </label>
        <select v-model="filter.paymentStatus" class="form-select">
          <option value="--">--</option>
          <option value="Payé">Payé</option>
          <option value="Impayé">Impayé</option>
        </select>
      </div>
      <div class="col-auto">
        <label for=""> </label>
        <button @click="toFilter(filter)" class="btn btn0 btn-secondary">
          <i class="bi bi-funnel"></i> Rechercher
        </button>
      </div>
    </div>
    <br />
    <div class="row justify-content-end">
      <div class="col-auto">
        <button @click="refresh()" class="btn btn0 btn-secondary">
          <i class="bi bi-arrow-clockwise"></i>
          Actualiser
        </button>
      </div>
      <div class="col-auto">
        <button
          @click="$router.push({ name: 'purchases-items-create' })"
          class="btn btn-primary"
        >
          <i class="bi bi-plus-square"></i>
          Créer de nouveaux achats.
        </button>
      </div>
    </div>
    <br />
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Référence</th>
          <th scope="col">Fournisseur</th>
          <th scope="col">Date</th>
          <th scope="col" class="text-end">M. Total</th>
          <th scope="col" class="text-end">Total Qté</th>
          <th scope="col" class="text-end">M. Payé</th>
          <th scope="col" class="text-end">M. Impayé</th>
          <th scope="col">Créer par</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(purchase, index) in purchases" :key="index++">
          <td>
            {{ index }}
          </td>
          <td>
            {{ purchase.reference }}
          </td>
          <td>
            <span v-if="purchase.supplier">
              {{ purchase.supplier.society }}
            </span>
          </td>
          <td>{{ purchase.date | date }}</td>
          <td class="text-primary text-end">
            <span v-if="purchase.totalPriceTTC != null ">
              {{ purchase.totalPriceTTC | toFixedWithSpace }}
            </span>
          </td>
          <td class="text-secondary text-end">
            <span v-if="purchase.totalQuantity != null">
              {{ purchase.totalQuantity | toFixedWithSpace }}
            </span>
          </td>
          <td class="text-success text-end">
            <span v-if="purchase.amountPaid != null ">
              {{ purchase.amountPaid | toFixedWithSpace }}
            </span>
          </td>
          <td class="text-end">
            <span
              v-if="purchase.amountUnpaid != null "
              class="text-danger"
            >
              {{ purchase.amountUnpaid | toFixedWithSpace }}
            </span>
          </td>
          <td>
            <!-- {{ purchase.user_reference }}
            <br /> -->
            <span v-if="purchase.user">
              {{ purchase.user.name }}
            </span>
          </td>
          <td  class="p-0 m-0">
            <button
              @click="
                $router.push({
                  name: 'purchases-details',
                  params: { reference: purchase.reference },
                })
              "
              class="btn"
            >
              <i class="bi bi-info-square fs-5"></i>
            </button>
          </td>

        </tr>
      </tbody>

      <tfoot>
        <tr>
          <td scope="col"></td>
          <td scope="col"></td>
          <td scope="col"></td>
          <td scope="col"></td>
          <td scope="col" class="text-end">
            <span
             
              class="bg-primary py-1 px-2 rounded text-white"
            >
              {{ totalPriceTTC | toFixedWithSpace }}
            </span>
          </td>
          <td scope="col" class="text-end">
            <span
              
              class="bg-secondary py-1 px-2 rounded text-white"
            >
              {{ totalQuantity | toFixedWithSpace }}
            </span>
          </td>
          <td scope="col" class="text-end">
            <span
              
              class="bg-success py-1 px-2 rounded text-white"
            >
              {{ totalPaid | toFixedWithSpace }}
            </span>
          </td>
          <td scope="col" class="text-end">
            <span
              v-if="totalUnpaid "
              class="bg-danger py-1 px-2 rounded text-white"
            >
              {{ totalUnpaid | toFixedWithSpace }}
            </span>
          </td>
          <td scope="col"></td>
          <td scope="col"></td>
          <td scope="col"></td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      search_value: "",
      addedToday: false,
      sortValue: "",
      filter: {
        startDate: new Date(new Date().setFullYear(new Date().getFullYear()))
          .toLocaleString("en-CA")
          .split(",")[0],
        endDate: new Date(new Date().setFullYear(new Date().getFullYear()))
          .toLocaleString("en-CA")
          .split(",")[0],
      },
    };
  },
  computed: {
    ...mapGetters("purchase", {
      purchases: "getAll",
      totalQuantity: "getPurchasesTotalQuantity",
      totalPriceTTC: "getPurchasesTotalPriceTTC",
      totalPaid: "getPurchasesTotalPaid",
      totalUnpaid: "getPurchasesTotalUnpaid",
    }),
    ...mapGetters("supplier", {
      suppliers: "getAll",
    }),
    ...mapGetters("item", {
      items: "getAll",
    }),
    ...mapGetters("section", {
      sections: "getAll",
    }),
    ...mapGetters("auth", {
      userRole: "getUserRole",
    }),
  },
  mounted() {
    this.$store.dispatch("purchase/getAll");
    this.$store.dispatch("supplier/getAll");
  },
  methods: {
    async search(value) {
      return await this.$store.commit("purchase/search", value);
    },
    async refresh() {
      return await this.$store.dispatch("purchase/getAll");
    },

    async toFilter(data) {
      return await this.$store.dispatch("purchase/filter", data);
    },
  },
  filters: {
    toFixedWithSpace: (value) => {
      return parseFloat(value)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
    },
    date: (value) => {
      return moment(value).format("DD-MM-YYYY");
    },
  },
};
</script>
